import React from 'react'

import Layout from '../components/layout'
import Head from '../components/head'

import '../styles/grid.scss'
import '../styles/index.scss'



const JoinPage = () => {
 
  return(
 
    <Layout >
      <Head title="Davetiye" description="Çember yakınlarınla bir araya gelerek altın günü modeli ile birikim yapabildiğin bir uygulamadır. Çember ahalisine katılmak için e-posta adresini bırakabilirsin." />

      <div className="row ctaBanner davet">
        <div className="row">
            <div className="col-2 hidden-sm"></div>
            <div className="col-1">
           
            <span role="img" aria-label="post"> 
            📬
            </span>
            </div>
            <div className="col-6">
              <div className="col-12 ">
                <h2>Ahali'ye katıl, </h2>
                <h2>önce <b>sen</b> dene!</h2>
               </div>

              
               <div className="col-12">
               <form  action="/success"  name="davetiye-join" method="post" netlify-honeypot="bot-field" data-netlify="true" >
                 <input type="hidden" name="form-name" value="davetiye-join" />
                <input required type="email" name="email" placeholder="E-posta adresin" />
                <button>Katıl</button>
              
               </form>
               </div>
            </div>
            <div className="col-1">
                <span role="img" aria-label="post"> 
                    ✉️
                </span>
            </div>
            <div className="col-2 hidden-sm"></div>
        </div>
      </div>
     

      


    </Layout>
  )
}

export default JoinPage